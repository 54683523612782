<template>
  <sub-page
      :title="$t('ProjectFiles')"
      icon="mdi-folder"
      v-model="$store.state.settings.pageData"
  >
    <ws-data-table
        :items="items"
        :headers="headers"
    >

    </ws-data-table>

  </sub-page>
</template>

<script>
export default {
  name: "ProjectParticipants",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      entity : {
        name : 'Super project'
      },
      items : [
        { name : 'jointable_of_project.docx'    ,   date : 12312333  },
        { name : 'pedro_shantal_file.xlsx'      ,   date : 12312333  }
      ]
    }
  },

  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Date') , value : 'date' }
      ]
    },
  }
}
</script>

<style scoped>

</style>